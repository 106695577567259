import * as React from 'react';

import { connect } from 'react-redux';
import { ApplicationState } from '@app/store/index';
import moment from "moment";
import { dispatchToProps, ExtendableItemEditor } from "@app/components/Pages/ItemEditor";

import { Loading } from "@app/components/UI/Loading";

import { ItemsState } from "@common/react/store/ItemList";
import { CustomQuestion, getMergedQuestions, Question } from "@app/objects/Question";
import { StatusReport } from "@app/objects/StatusReport";
import { request } from "@app/components/Api";

import CommentTree from "@app/components/Forms/Comment/CommentTree";
import { dateFormat, getUserName } from "@app/components/Utils/Utils";
import { Link } from "react-router-dom";
import { User, UserRole } from "@app/objects/User";
import { Injury } from '@app/objects/Injury';


interface ReduxProps {
    questions: ItemsState<Question>;

    user: User | null;
}

interface OwnProps {
    injury: number;
    reportId: number;
    onCancel: () => void;
    daysInProgram: string;

}



type Props = OwnProps & ReduxProps;

class ViewStatusReport extends ExtendableItemEditor<StatusReport, Props> {
    constructor(props: Props) {
        super(props);

        this.type = 'statusReport';
        this.path = 'getStatusReport';
        this.backPath = '/companies';

        this.itemsStore = 'statusReports';
        this.itemsPath = 'statusReportList';

        this.defaultItem = { id: -1, injuryId: props.injury, answers: { count: 0, list: [], execution: 0, offset: 0 } };
        this.state = { ...this.state, expandedRows:[]}
        this.additionalParams = {
            withAnswers: true,
            withInjury: true,
            withSchedule: true
        };

        this.handleRead = this.handleRead.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    componentWillMount() {
        this.props.itemActions.loadItem(this.type, this.path, this.props.reportId, this.defaultItem, this.additionalParams);
        this.props.pagesActions.reqPages('questions', 'questionList', 'question', { page: 1, text: '', count: 100 });
        this.handleRead();
    }

    handleRowClick(rowId) {
        const currentExpandedRows = this.state.expandedRows;
        const isRowCurrentlyExpanded = currentExpandedRows.indexOf(rowId) > -1;
        
        const newExpandedRows = isRowCurrentlyExpanded ?
            currentExpandedRows.filter(id => id !== rowId) :
            currentExpandedRows.concat(rowId);
            
        this.setState({ expandedRows: newExpandedRows },()=> console.log(this.state.expandedRows));
    }

    componentWillReceiveProps(newProps) {
        if (newProps.reportId !== this.props.reportId) {
            this.props.itemActions.loadItem(this.type, this.path, newProps.reportId, this.defaultItem, this.additionalParams);
        }
    }

    handleRead() {
        const data = {
            injury: this.props.injury,
            employee: null,
            setAsRead: true,
            withAnswers: true
        };


        request('statusReportList', data).then((a: any) => {
            this.setState({ items: a.list });
        });
    }

    totalDaysInProgram(injury: Injury) {
        let days: number | null = 0;
        if (injury) {
            days = injury.totalDaysInProgram;
            if (injury.totalDaysInProgram == -1 && injury.schedules && injury.schedules.list && injury.schedules.list.length > 0 && injury.schedules.list[injury.schedules.list.length - 1].actualStartTime)
                days = Math.round((new Date().getTime() - injury.schedules.list[injury.schedules.list.length - 1].actualStartTime) / (1000 * 60 * 60 * 24)) + 1;
        }
        return days;
    }

    public render() {
        const item: StatusReport = this.props.item;
        const items: StatusReport[] = this.state.items;
        console.log(this.state);
        const user = this.props.user;
        const questions = this.props.questions.items;
        if (item && item.id === +this.props.reportId && questions.length > 0 && item.injury && items && items.length > 0) {

            const schedule = item.injury && item.injury.schedules && item.injury.schedules.list[0];
            return (
                <React.Fragment>

                    <table style={{ width: "100%" }}>
                        <tr>
                            <td>
                                <b>Last Journal Entry</b> : {moment(items[0].time).format("MM/DD/YY LT")}
                            </td>
                            <td>
                                <b>Name</b> : {getUserName(item.injury.employe)}
                            </td>
                            <td>
                                <b>Case #</b> : {item.injury.vccCase}
                            </td>
                            <td>
                                <b>Work Site/Start Date</b>:{schedule && schedule.company ? schedule.company.name + ' / ' + dateFormat(schedule.startTime) : '-'}
                            </td>
                            <td>
                                <Link title="Voice Message" to={'/calls?voice=true&vccCases=' + item.injury.vccCase}>Voice Message</Link>
                            </td>
                            <td>
                                <Link title="Alerts" to={'/alert-list?vccCases=' + item.injury.vccCase}>Alerts</Link>
                            </td>
                            <td>
                                <Link title="TimeCard" to={'/calls?vccCases=' + item.injury.vccCase}>TimeCard</Link>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Employer</b> : {item.injury.employe.companyName}
                            </td>
                            <td>
                                <b>Claim #</b> : {item.injury.claimNumber}
                            </td>
                            <td>
                                <b>Actual Days Worked</b> : {item.injury.totalDaysWorked !== null ? item.injury.totalDaysWorked : 0}
                            </td>
                        </tr>
                    </table>
                    <table style={{ width: "100%", marginTop: "50px" }}>
                        <tr>
                            <th>Time</th>
                            <th>Subject</th>
                            <th>Notes/Comments</th>
                            <th>Category</th>
                            <th>Rating</th>
                        </tr>
                        {(items.length > 0) && items.map((item: StatusReport, index: number) => {
                            const currentAnswers = item.answers ? item.answers.list : [];
                            const vals = currentAnswers.length > 0 && currentAnswers[0].questionId == 11 ? JSON.parse(currentAnswers[0].text) : undefined;
                            if (vals) {
                                const firstItem = items.indexOf(item) == 0;
                                const showRow = this.state.expandedRows && this.state.expandedRows.indexOf(item.id) > -1 || firstItem;
                                const showWSR = vals.general.join().indexOf("WSR") >= 0 && showRow;
                                const showPlease = vals.general.join().indexOf("Please") >= 0 && showRow;
                                const showGeneral = vals.type.join().indexOf("0") >= 0 && showRow;
                                const showChallenges = vals.type.join().indexOf("1") >= 0 && showRow;
                                
                                return <React.Fragment>
                                    
                                    <tr   > <td>
                                        <button hidden={firstItem} className="btn btn-sm btn-default" onClick={e => this.handleRowClick(item.id)} title="View details" ><i className="fa fa-eye"></i></button></td><td>{moment(item.time).format("MM/DD/YY LT")}</td>  <td></td> <td></td> <td></td></tr>
                                    <tr hidden={!showPlease || !showGeneral}>
                                        <td></td>
                                        <td>
                                            General
                            </td>
                                        <td>
                                            Please provide updated medical status report
                            </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr hidden={!showWSR || !showGeneral}>
                                        <td></td>
                                        <td>
                                            General
                            </td>
                                        <td>
                                            Worksite Supervisor Review (WSR)
                            </td>
                                        <td>
                                            Attendance
                            </td>
                                        <td>
                                            {vals.attendance}
                                        </td>
                                    </tr>
                                    <tr hidden={!showWSR || !showGeneral}>
                                        <td></td>
                                        <td>

                                        </td>
                                        <td>
                                            Worksite Supervisor Review (WSR)
                            </td>
                                        <td>
                                            Work Performance
                            </td>
                                        <td>
                                            {vals.workPerformance}
                                        </td>
                                    </tr>
                                    <tr hidden={!showWSR || !showGeneral}>
                                        <td></td>
                                        <td>

                                        </td>
                                        <td>
                                            Worksite Supervisor Review (WSR)
                            </td>
                                        <td>
                                            Meeting Worksite Guidelines
                            </td>
                                        <td>
                                            {vals.meetingWorksiteGuidelines}
                                        </td>
                                    </tr>
                                    <tr hidden={vals.challenges.length == 0 || !showChallenges}>
                                        <td style={{ verticalAlign: 'top' }}></td>
                                        <td style={{ verticalAlign: 'top' }}>
                                            Challenges
                            </td>
                                        <td>
                                            <table>
                                                {vals.challenges.map((v) =>
                                                    <tr><td>{v}</td></tr>)}
                                            </table>
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                </React.Fragment>
                            }
                        })}

                    </table>
                    <React.Fragment>
                        <hr />
                        <h4>Your Response:</h4>
                        <button type="button" className="btn btn-default read-btn" onClick={this.handleRead} disabled={item.read}>Read</button>
                        <CommentTree objectType="statusReport" objectId={item.id} withoutAddButton={true} insertBefore={true} />
                    </React.Fragment>
                </React.Fragment>
            )
        }

        return <Loading />
    }
}

export default connect<ReduxProps, any, OwnProps>(
    (state: ApplicationState) => ({
        ...state.statusReport,
        questions: state.questions,
        user: state.login.user,
        expandedRows :[]
    }),
    dispatchToProps
)(ViewStatusReport);