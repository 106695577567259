import * as React from 'react';

import {connect} from 'react-redux';
import {dispatchToProps} from "@app/components/Pages/ItemEditor";
import {User, UserRole} from "@app/objects/User";
import {Loading} from "@app/components/UI/Loading";
import {ApplicationState} from "@app/store";
import {request} from "@app/components/Api";
import {Field, FieldProps, Form, Formik, FormikProps} from 'formik';
import {Call, CallType} from "@app/objects/Call";
import Datepicker from "@common/react/components/Forms/Datepicker/Datepicker";
import Timepicker from "@common/react/components/Forms/Timepicker/Timepicker";
import * as moment from "moment";
import Button from "@common/react/components/Forms/Button";
import {Injury} from "@app/objects/Injury";
import Autocomplete, {Option} from "@common/react/components/Forms/Autocomplete/Autocomplete";
import Schedule from "@app/objects/Schedule";
import * as Yup from 'yup';
import {List} from "@common/typescript/objects/List";
import {getUserName, phoneMask, phoneReplace, timepickerProps} from "@app/components/Utils/Utils";
import {phoneValidator} from "@common/react/utils/validationHelpers";

import MaskedInput from 'react-text-mask';
import { Employee } from '@app/objects/Employee';

interface ComponentProps {
	ids: number[];
	onClose: () => void;
    injury?: Injury;
    employee?: Employee;
	onSubmit?: () => void;
}

interface ReduxProps {
	user: User | null;
}

type Props = ComponentProps & ReduxProps;

interface FormValues {
	time: number,
	startTime: Call,
	endTime: Call,
	startLunchTime: Call,
	endLunchTime: Call,
	injuryId: number | null;
	scheduleId: number | null;
	employeeId: number | null;
	injuryName: string;
	phone: string;
	schedules: Schedule[];
}

interface ComponentState {
	isLoading: boolean;
    success: boolean;
    employee: Employee | null | undefined;
	error: string | null;
	calls: Call[];
}


const validationSchema = Yup.object().shape({
	injuryId: Yup.mixed().test('is-not-null', 'Required field!', value => value != null),
	scheduleId: Yup.mixed().test('is-not-null', 'Required field!', value => value != null),
	phone: phoneValidator
});

function removeDuplicates(schedulesArray: Schedule[]) {
	return schedulesArray.filter((obj, pos, arr) => {
		if (obj.companyName) {
			return arr.map(mapObj => mapObj.companyName && mapObj.companyName).indexOf(obj.companyName) === pos;
		}
		return false;
	});
}

class CallPopupEditor extends React.Component<Props, ComponentState> {
    form: Formik<FormValues> | null = null;
	constructor(props: any) {
		super(props);
		
		this.state = {
			calls: [],
			isLoading: false,
			success: false,
            error: null,
            employee: this.props.employee
		};
		
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
	}
	
	componentDidMount() {
		this.loadCalls(this.props.ids);

		const injury = this.props.injury;
		
		if (injury) {
			request<List<Schedule>>('scheduleList', { page: 1, count: 100, objectId: injury.id, notClosed: true }).then((result) => {
				const filteredSchedules = removeDuplicates(result.list);
				
				if(this.form) {
					const formikBag = this.form.getFormikBag();
					
					this.form.setValues({
						...formikBag.values,
						schedules: filteredSchedules,
						scheduleId: filteredSchedules[0] ? filteredSchedules[0].id : null
					});
				}
			})
		}
	}
	
	componentWillReceiveProps(nextProps: Readonly<Props>) {
		this.loadCalls(nextProps.ids);
    }

    convertTimeToEmployeeTimeZone2(time, employee) {
        //console.log(call.time, moment.utc(call.time), moment.utc(call.time).subtract(call.employee != null ? call.employee.timeZone * -1 : 0, 'hours'))
        if (employee != null && employee.timeZone > 0)
            return moment.utc(time).add(employee != null ? employee.timeZone : 0, 'hours')
        else if (employee != null && employee.timeZone < 0)
            return moment.utc(time).subtract(employee != null ? employee.timeZone * -1 : 0, 'hours')
        else
            return moment.utc(time);
    }

    convertTimeToEmployeeTimeZoneReverse(time, employee) {
        //return moment.utc(time);
        //console.log(time,moment.utc(time), moment.utc(time).add(call.employee != null ? call.employee.timeZone * -1 : 0, 'hours'))

        if (employee != null && employee.timeZone < 0)
            return moment.utc(time).add(employee != null ? employee.timeZone * -1 : 0, 'hours')
        else if (employee != null && employee.timeZone > 0)
            return moment.utc(time).subtract(employee != null ? employee.timeZone : 0, 'hours')
        else
            return moment.utc(time);
    }
	
	loadCalls(ids) {
		if (ids && ids[0] !== -1) {
			this.setState({isLoading: true});

			request<List<Call>>('callList', { ids: ids, withInjury: true, withEmployee: true }).then((result: any) => {
					this.setState({
						calls: result.list,
						isLoading: false
					});
				}
			)
		}
	}

	handleSubmitForm(values: FormValues, {resetForm}) {
		if(values.startTime.time === null && values.endTime.time === null) {
			this.setState({
				error: 'You must select "Start Time" or "End Time"!'
			});

			return;
		}
		
		this.setState({isLoading: true});

		let data = [{...values.startTime, employee: null, injury: null}, {...values.endTime}, {...values.startLunchTime}, {...values.endLunchTime}];
		
		data = data.filter(call => call.time)
			.map(call => {
				if (values.injuryId) call.injuryId = values.injuryId;
				if (values.scheduleId) call.scheduleId = values.scheduleId;
				if (values.employeeId) call.employeeId = values.employeeId;

                let time = moment.utc(values.time);

				time.hour(+call.time.toString().split(':')[0]);
				time.minute(+call.time.toString().split(':')[1]);
				
				call.phone = phoneReplace(values.phone);
	
                call.time = +time || 0;
                call.time = parseInt(this.convertTimeToEmployeeTimeZoneReverse(call.time, this.state.employee).format("x"));
				
				return call;
			});
		
		request('customCalls', data).then((result) => {
			this.setState({
				isLoading: false,
				success: true
			});
			
			resetForm();
			
			//this.props.onSubmit && this.props.onSubmit();
			debugger
			setTimeout(() => {
				this.props.onClose && this.props.onClose();
				}, 0)
		}).catch((error: string) => {
			this.setState({
				error: error,
				isLoading: false
			});
		});
	}

    public render() {
        const { ids, injury, employee } = this.props;
        const { calls, isLoading } = this.state;
        
		if (ids) {
			let startTime: any = {id: -1, callType: CallType.ClockForDay, time: null},
				endTime: any = {id: -1, callType: CallType.ClockForDay, time: null},
				startLunchTime: any = {id: -1, callType: CallType.ClockForLunch, time: null},
				endLunchTime: any = {id: -1, callType: CallType.ClockForLunch, time: null};

			if (calls && calls.length) {
				startTime = {...calls[0], time: moment(calls[0].time)};

				if (calls[1] && calls[1].callType === CallType.ClockForDay) {
					endTime = {...calls[1], time: moment(calls[1].time)};
				} else if (calls[1] && calls[1].callType === CallType.ClockForLunch) {
					startLunchTime = {...calls[1], time: moment(calls[1].time)};
					if (calls[2] && calls[2].callType === CallType.ClockForLunch) {
						endLunchTime = {...calls[2], time: moment(calls[2].time)};
					}
					if (calls[3] && calls[3].callType === CallType.ClockForDay) {
						endTime = {...calls[3], time: moment(calls[3].time)};
					}
				}
			}

			return <React.Fragment>
				<div className="editor-page editor-page_white">
					<div className="clearfix">
						<Formik
							ref={node => (this.form = node)}
							initialValues={{
								time: startTime.time || Date.now(),
								startTime: startTime,
								endTime: endTime,
								startLunchTime: startLunchTime,
								endLunchTime: endLunchTime,
								injuryId: injury ? injury.id : null,
								employeeId: injury ? injury.employeId : null,
								scheduleId: null,
								injuryName: '',
								phone: '',
								schedules: []
							}}
							onSubmit={this.handleSubmitForm}
							validationSchema={validationSchema}
							render={(formikBag: FormikProps<FormValues>) => (
								<form id='call-form' onSubmit={e => {e.preventDefault(); e.stopPropagation(); formikBag.handleSubmit()}}>
									{calls && calls[0] && ids[0] !== -1 && this.props.user && this.props.user.role !== UserRole.External &&
										<div className="row">
											<div className="col-sm-6">
												<label>{calls[0].injury && calls[0].injury.vccCase} / {calls[0].employee && getUserName(calls[0].employee)}</label>
											</div>
										</div>
									}
									{ids[0] === -1 &&
									<div className="row">
										{!injury &&
											<Field
												name="injuryId"
												render={({field, form}: FieldProps<FormValues>) => (
													<div
														className={`form-group col-sm-6 ${form.errors.injuryId && form.touched.injuryId ? 'has-error' : ''}`}>
														<label>VCC Case*</label>
														<div className="is-relative">
															<Autocomplete
																type="injuryList"
																renderOption={(item: Injury) => <Option
																	key={item.id + ''} value={`${item.id}`}
																	title={item.vccCase} {...{item: item}}>{item.vccCase}</Option>}
																onSelect={(value, option) => {
																	debugger
																	const filteredSchedules = removeDuplicates(option.props.item.schedules.list);
                                                                    
                                                                    this.setState({
                                                                        employee: option.props.item.employe
                                                                    });
																	formikBag.setValues({
                                                                        ...formikBag.values,
																		injuryId: +value,
																		injuryName: option.props.item.name,
																		employeeId: option.props.item.employeId,
																		schedules: filteredSchedules,
																		scheduleId: filteredSchedules[0] ? filteredSchedules[0].id : null
																	});
																}}
																onChange={(value = '') => {
																	if (value === '') {
																		formikBag.setFieldValue('injuryId', null);
																		formikBag.setFieldValue('injuryName', '');
																		formikBag.setFieldValue('scheduleId', null);
																		formikBag.setFieldValue('employeeId', null);
																	}
																}}
																params={{
																	withSchedule: true,
                                                                    withActiveWorkSite: true,
                                                                    withEmployee:true
																}}
																loadOnFocus={true}
																value={formikBag.values.injuryName}
																paramName="text"
															/>
															{form.touched.injuryId && form.errors.injuryId ? <div
																className="validation-message">{form.errors.injuryId}</div> : ''}
														</div>
													</div>
												)}
											/>
										}
										<Field
											name="scheduleId"
											render={({ field, form }: FieldProps<FormValues>) => (
												<div className={`form-group col-sm-6 ${form.errors.scheduleId && form.touched.scheduleId ? 'has-error' : ''}`}>
													<label>Schedule*</label>
													<div className="is-relative">
														<select className="form-control" id="scheduleId" disabled={!formikBag.values.injuryId} {...field}>
															{form.values.schedules && form.values.schedules.map( (value: Schedule, index: number) =>
																<option value={value.id} key={value.id}>{value.companyName}</option>
															)}
														</select>
														{form.touched.scheduleId && form.errors.scheduleId ? <div className="validation-message">{form.errors.scheduleId}</div> : ''}
													</div>
												</div>
											)}
										/>
									</div>
									}
									<div className="row">
										<div className={`form-group col-sm-6`}>
											<label>Date</label>
											<Datepicker
												value={formikBag.values.time}
												format="MM/DD/YY"
												onChange={(date) => {
													formikBag.setFieldValue('time', date);
												}}
											/>
										</div>
										<Field
											name="phone"
											render={({ field, form }: FieldProps<FormValues>) => (
												<div className={`form-group col-sm-6 ${form.errors.phone && form.touched.phone ? 'has-error' : ''}`}>
													<label htmlFor="phone">Phone</label>
													<div className="is-relative">
														<MaskedInput
															mask={phoneMask}
															className="form-control"
															placeholder="+1 (___) ___-____"
															guide={true}
															id="phone"
															{...field}
														/>
														{form.touched.phone && form.errors.phone ? <div className="validation-message">{form.errors.phone}</div> : ''}
													</div>
												</div>
											)}
										/>
									</div>
									<div className="row">
										<div className={`form-group col-sm-6`}>
											<label>Start Time</label>
											<div className="timepicker-component">
												<Field
													name="startTime.time"
													render={({field, form}: FieldProps<FormValues>) => (
														<Timepicker value={field.value} onChange={time => formikBag.setFieldValue('startTime.time', time)} antdProps={timepickerProps}/>
													)}
												/>
											</div>
										</div>
										<div className="form-group col-sm-6">
											<label>End Time</label>
											<div className="timepicker-component">
												<Field
													name="endTime.time"
													render={({field, form}: FieldProps<FormValues>) => (
														<Timepicker value={field.value} onChange={time => formikBag.setFieldValue('endTime.time', time)} antdProps={timepickerProps}/>
													)}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-sm-6">
											<label>Start Lunch Time</label>
											<div className="timepicker-component">
												<Field
													name="startLunchTime.time"
													render={({field, form}: FieldProps<FormValues>) => (
														<Timepicker value={field.value} onChange={time => formikBag.setFieldValue('startLunchTime.time', time)} antdProps={timepickerProps}/>
													)}
												/>
											</div>
										</div>
										<div className="form-group col-sm-6">
											<label>End Lunch Time</label>
											<div className="timepicker-component">
												<Field
													name="endLunchTime.time"
													render={({field, form}: FieldProps<FormValues>) => (
														<Timepicker value={field.value} onChange={time => formikBag.setFieldValue('endLunchTime.time', time)} antdProps={timepickerProps}/>
													)}
												/>
											</div>
										</div>
									</div>
									{this.state.error ? <div className="alert alert-danger form-group">{this.state.error}</div> : ''}
									<div className="text-center form-group">
										<Button isLoading={this.state.isLoading}>Save</Button>
										<button type="button" className="btn btn-danger" onClick={() => {
											formikBag.resetForm();
											this.props.onClose();
										}}>Cancel</button>
									</div>
								</form>
							)}
						/>
					</div>
				</div>
			</React.Fragment>
		}
		
		return <Loading/>;
	}
}

export default connect<ReduxProps, any, ComponentProps>(
	(state: ApplicationState) => ({
		user: state.login.user
	}),
	dispatchToProps
)(CallPopupEditor);