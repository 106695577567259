import * as React from 'react';

import MaskedInput from 'react-text-mask';
import {request} from "@app/components/Api";

import Modal from "antd/lib/modal";
import {Call, CallType, playCallVoice} from "@app/objects/Call";
import {minutesToHours, phoneFormat, phoneMask, phoneReplace} from "@app/components/Utils/Utils";
import {List} from "@common/typescript/objects/List";
import {deleteConfirmation} from "@common/react/components/Modal/Modal";
import {phoneRegexp} from "@common/react/utils/validationHelpers";

import * as moment from 'moment';

import TimePicker from 'antd/lib/time-picker';

interface OwnProps {
	visible: boolean;
	calls: List<Call> | null;
	onCancel: () => void;
	onListChange: (id: number, time: number) => void;
    vccCase: string;
    employeeName: string;
}

interface State {
	editableItem: Call | null;
	hasError: boolean;
	phoneError: boolean;
}

export default class CallListEditablePopup extends React.Component<OwnProps, State> {
	constructor(props: OwnProps) {
		super(props);
		
		this.state = {
			editableItem: null,
			hasError: false,
			phoneError: false
		};

		this.handleInputCancel = this.handleInputCancel.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.setItemPropValue = this.setItemPropValue.bind(this);
		this.handleNewItem = this.handleNewItem.bind(this);
		this.handleInputSubmit = this.handleInputSubmit.bind(this);
	}
	
	static checkCall(item, index) {
		
		let result = true;

		if (item.event) {
			const callTime = new Date(item.time),
				startTime = new Date(item.event.startTime),
				endTime = new Date(item.event.endTime);

			if (index === 0) {
				callTime.getHours() * 60 + callTime.getMinutes() - (startTime.getHours() * 60 + startTime.getMinutes()) > item.event.lateInAlert ? result = false : result = true;
			} else if (index === 3) {
				endTime.getHours() * 60 + endTime.getMinutes() - (callTime.getHours() * 60 + callTime.getMinutes()) > item.event.lateOutAlert ? result = false : result = true;
			}
		}

		return result;
	}

	handleInput(record) {
		debugger
		this.setState({
			editableItem: {id: record.id, ...record},
			hasError: false,
			phoneError: false
		});
		debugger
	}

	handleInputCancel() {
		this.setState({
			editableItem: null,
			hasError: false,
			phoneError: false
		});
    }

    convertTimeToEmployeeTimeZone(call) {
        //return moment.utc(call.time);
        //console.log(call.time, moment.utc(call.time), moment.utc(call.time).subtract(call.employee != null ? call.employee.timeZone * -1 : 0, 'hours'))
        if (call.employee != null && call.employee.timeZone > 0)
            return moment.utc(call.time).add(call.employee != null ? call.employee.timeZone : 0, 'hours')
        else if (call.employee != null && call.employee.timeZone < 0)
            return moment.utc(call.time).subtract(call.employee != null ? call.employee.timeZone *-1 : 0, 'hours')
        else
            return moment.utc(call.time);
    }

    convertTimeToEmployeeTimeZone2(call) {
        //console.log(call.time, moment.utc(call.time), moment.utc(call.time).subtract(call.employee != null ? call.employee.timeZone * -1 : 0, 'hours'))
        if (call.employee != null && call.employee.timeZone > 0)
            return moment.utc(call.time).add(call.employee != null ? call.employee.timeZone : 0, 'hours')
        else if (call.employee != null && call.employee.timeZone < 0)
            return moment.utc(call.time).subtract(call.employee != null ? call.employee.timeZone * -1 : 0, 'hours')
        else
            return moment.utc(call.time);
    }

    convertTimeToEmployeeTimeZoneReverse(time, call) {
        //return moment.utc(time);
        //console.log(time,moment.utc(time), moment.utc(time).add(call.employee != null ? call.employee.timeZone * -1 : 0, 'hours'))
        if (call.employee != null && call.employee.timeZone < 0)
            return moment.utc(time).add(call.employee != null ? call.employee.timeZone * -1 : 0, 'hours')
        else if (call.employee != null && call.employee.timeZone > 0)
            return moment.utc(time).subtract(call.employee != null ? call.employee.timeZone : 0, 'hours')
        else
            return moment.utc(time);
    }

    convertTimeToEmployeeTimeZoneReverseAdd(time, timeZone) {
        //return moment.utc(time);
        if (timeZone < 0)
            return moment.utc(time).add(timeZone * -1, 'hours');
        else
            return moment.utc(time).subtract(timeZone, 'hours');
    }

	handleInputSubmit() {
		const item = this.state.editableItem;
		
		if(item) {
			if(!item.time) {
				this.setState({ hasError: true });
				return;
			}

			if(item.phone && !item.phone.match(phoneRegexp)) {
				this.setState({ phoneError: true });
				return;
			}

            item.phone = phoneReplace(item.phone);
            //item.time = parseInt(this.convertTimeToEmployeeTimeZone2(item).format("x"));
            //console.log(item)
			request<Call>('call', item).then(
                (result) => {
                    //console.log(result);
					this.props.onListChange(result.injuryId, result.time);
					this.handleInputCancel();
				}
			);
		}
	}

	setItemPropValue(id: number, propName: string, value: any) {
		this.setState((prevState) => ({
			editableItem: {
				...prevState.editableItem,
				[propName]: value
			} as Call
		}));
	}
	
	handleNewItem(firstCall: Call) {
		this.setState({ editableItem: { id: -1, callType: 0, injuryId: firstCall.injuryId, employeeId: firstCall.employeeId, eventId: firstCall.eventId } as Call});
	}

	handleDelete(event, item) {
		event.preventDefault();
		
		deleteConfirmation(() => {
			request<Call>('call', {...item, deleted: true}).then((result) => this.props.onListChange(result.injuryId, result.time))
		});
	}

    public render() {
        const { calls, visible, onCancel, vccCase, employeeName } = this.props;
        const { editableItem } = this.state;
        console.log(calls);
		return (
			<Modal
                title={`Calls ${vccCase ? 'for ' + vccCase : ''} / ${employeeName ? employeeName:''}`}
				visible={visible}
				footer={null}
				onCancel={onCancel}
				destroyOnClose={true}
				afterClose={this.handleInputCancel}
				width={650}
			>
				<table className="injury-calls-table">
					<tbody>
					{calls && calls.list.length > 0
						? <React.Fragment>
							<tr>
								<td colSpan={8}>
                                        <div className="injury-calls-table__headline">
                                            {this.convertTimeToEmployeeTimeZone(calls.list[0]).format(`dddd, MM/DD/YY`)}
										<button className="btn btn-default pull-right injury-calls-table__add-btn"  type="button" title="Add Call" onClick={e => this.handleNewItem(calls.list[0])}>Add Call</button>
									</div>
								</td>
							</tr>
                                {calls.list.filter(function (item) { return item.callType != CallType.Fake; }).map((item, index) => {
								const isEdit = editableItem && editableItem.id === item.id;
									
								return <tr key={item.id}
								    className={`injury-calls-table__row ${index % 2 === 0 ? 'even' : 'odd'} ${CallListEditablePopup.checkCall(item, index) ? '' : 'late'}`}>
									<td>
										{item.callType == CallType.ClockForDay && (item.duration > 0 && <b>End</b> || <b>Start</b>)}
										{item.callType == CallType.ClockForLunch && (item.duration > 0 && <b>End</b> || <b>Start</b>)}
										{item.callType == CallType.Record && <div className="text-center"><i className="fa fa-play"></i></div>}
										{item.callType == CallType.Call && <div className="text-center"><i className="fa fa-phone"></i></div>}
										{item.callType == CallType.Info && <div className="text-center"><i className="fa fa-info-circle"></i></div>}
									</td>
									<td style={{width: '138px'}}>
										{isEdit ?
											<div className={`${this.state.hasError ? 'has-error' : ''}`}>
												<TimePicker
                                                    defaultValue={this.convertTimeToEmployeeTimeZone(item)}
													format={'h:mm a'}

                                                    onChange={time => this.setItemPropValue(item.id, 'time', +this.convertTimeToEmployeeTimeZoneReverse(time,item))}
													use12Hours={true}
												/>
											</div>
                                            : this.convertTimeToEmployeeTimeZone(item).format('h:mm a')
										}
									</td>
									<td>
										{item.duration > 0 ? minutesToHours(item.duration) : <span>-</span>}
									</td>
									<td style={{width: '120px'}}>
										{isEdit ?
											<div className={`${this.state.phoneError ? 'has-error' : ''}`}><MaskedInput
												mask={phoneMask}
												className="form-control input-sm"
												placeholder="+1 (___) ___-____"
												guide={true}
												defaultValue={item.phone}
												name={'phone-' + item.id}
												onChange={(event) => this.setItemPropValue(item.id, 'phone', event.target.value)}
												ref={(input) => {
													this['phone-' + item.id] = input;
												}}
											/></div>
											: <span className="nobr">{phoneFormat(item.phone)}</span>
										}

									</td>
									<td>
										{item.callType === CallType.Record && item.files != null && item.files.list.length > 0
										&& <div className="injury-calls-table__flex">
											<button className="btn btn-sm" title="Play Record"
											        onClick={e => playCallVoice(item.files.list[0])}><i
												className="fa fa-play"/></button>
											<a className="btn btn-sm btn-primary" href={item.files.list[0].file.src}
											   download title="Download record"><i className="fa fa-download"></i></a>
										</div>
										}
									</td>
									<td className="text-right">
										{item.event && item.event.company && (item.event.company.name) + (item.companyPhone ? ' - ' : '')} {item.companyPhone && item.companyPhone.description}
									</td>
									<td>
										{item.manual ? 'M' : item.companyPhoneId === null ? 'U' : 'A'}
									</td>
									<td>
										<div className="text-right table-actions">
											{isEdit ?
												<div>
													<button className="btn btn-sm btn-primary" type="button"
													        title="Save" onClick={this.handleInputSubmit}><i
														className="fa fa-save"/></button>
													<button className="btn btn-sm btn-default" type="button"
													        title="Cancel" onClick={this.handleInputCancel}><i
														className="fa fa-times"/></button>
												</div>
												: <div>
													<button className="btn btn-sm btn-default" type="button"
													        title="Edit" onClick={e => this.handleInput(item)}><i
														className="fa fa-pencil"/></button>
													<button className="btn btn-sm btn-danger" type="button"
													        title="Delete" onClick={e => this.handleDelete(e, item)}><i
														className="fa fa-trash"/></button>
												</div>
											}
										</div>
									</td>
								</tr>
							})}
							{editableItem && editableItem.id === -1 && <tr className="injury-calls-table__row even">
								<td>
								</td>
								<td>
									<div className={`${this.state.hasError ? 'has-error' : ''}`}>
										<TimePicker
                                                defaultValue={this.convertTimeToEmployeeTimeZone(calls.list[0])}
                                                format={'h:mm a'}
                                                onChange={time => this.setItemPropValue(-1, 'time', +this.convertTimeToEmployeeTimeZoneReverseAdd(time, calls.list[0].employee != null ? calls.list[0].employee.timeZone:0))}
											use12Hours={true}
										/>
									</div>
								</td>
								<td></td>
								<td style={{width: '138px'}}>
									<div className={`${this.state.phoneError ? 'has-error' : ''}`}><MaskedInput
										mask={phoneMask}
										className="form-control input-sm"
										placeholder="+1 (___) ___-____"
										guide={true}
										name={'phone-' + editableItem.id}
										onChange={(event) => this.setItemPropValue(editableItem.id, 'phone', event.target.value)}
										ref={(input) => { this['phone-' + editableItem.id] = input;}}
									/></div>
								</td>
								<td>
								</td>
								<td>
								</td>
								<td>
								</td>
								<td>
									<div className="text-right table-actions">
										<button className="btn btn-sm btn-primary" type="button" title="Save" onClick={this.handleInputSubmit}><i className="fa fa-save"></i></button>
										<button className="btn btn-sm btn-default"  type="button" title="Cancel" onClick={this.handleInputCancel}><i className="fa fa-times"></i></button>
									</div>
								</td>
							</tr>}
						</React.Fragment>
						: <div className="not-found-title">Calls not found</div>
					}
					</tbody>
				</table>
				{calls && calls.list.length > 0 && <React.Fragment><br/>
					<strong>Total Duration: {minutesToHours(calls.list.reduce((acc, call) => acc + call.duration, 0))}</strong>
				</React.Fragment>}
			</Modal>
		)
	}
}