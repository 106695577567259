import * as React from "react";

import {ItemsState} from "@common/react/store/ItemList";
import {Injury, InjuryFile, InjuryStatus} from "@app/objects/Injury";
import {ActionCreatorsMapObject, bindActionCreators} from "redux";
import InjuryFilePopup from "@app/components/Pages/Employees/InjuryFiles/InjuryFilePopup";
import Table from "antd/lib/table";
import {dateFormat} from "@app/components/Utils/Utils";
import {connect} from "react-redux";
import {ApplicationState} from "@app/store";
import {getSelectedInjuryFilesActionCreators} from "@app/store/InjuryFile";
import {renderMobileCell} from "@common/react/components/Pages/ItemsPage";
import {request} from "@common/react/components/Api";

import {deleteConfirmation} from "@app/components/Utils/Modal";
import {UserRole} from "@app/objects/User";

interface ReduxProps {
	items: ItemsState<InjuryFile>
}

interface OwnProps {
	item: Injury;
	onUnsavedItemAdded: (unsavedItem: InjuryFile) => void; 
	onUnsavedItemDeleted: (unsavedItem: InjuryFile) => void;
	userRole: number;
	isEditable: boolean;

}

type InjuryFilesProps = OwnProps & ReduxProps & {actions: ActionCreatorsMapObject};

interface InjuryFilesState {
	addFileDialogIsVisible: boolean;
	unsavedItems: InjuryFile[];
}

class InjuryFiles extends React.Component<InjuryFilesProps, InjuryFilesState> {
	constructor(props) {
		super(props);
		
		this.state = {
			addFileDialogIsVisible: false,
			unsavedItems: []
		};

		this.deleteFile = this.deleteFile.bind(this);
		this.showAddFileDialog = this.showAddFileDialog.bind(this);
		this.hideAddFileDialog = this.hideAddFileDialog.bind(this);
		this.handleAddFileDialogOkClick = this.handleAddFileDialogOkClick.bind(this);
		this.addFile = this.addFile.bind(this);
	}

	componentWillMount() {
		if (this.props.item.id > 0) {
			this.props.actions.reqPages('injuryFiles', 'injuryFileList', 'injury', {
				page: 1,
                objectId: this.props.item.id,
                count: 1000000
			});
		}
	}

	showAddFileDialog() {
		this.setState({
			addFileDialogIsVisible: true
		});
	}

	hideAddFileDialog(){
		this.setState({
			addFileDialogIsVisible: false
		})
	}

	addFile(item: InjuryFile) {
		if (this.props.items) {
			
			request('injuryFile', item).then((response: any) => {
				this.props.actions.addItem('injuryFiles', response);
			});
		} else {
			this.setState((prevState) => ({
				unsavedItems: [...prevState.unsavedItems, item]
			}));
			this.props.onUnsavedItemAdded(item);
		}
	}

	deleteFile( event: any, item: any) {
		event.preventDefault();

		deleteConfirmation(() => {
			if (this.props.items) {
				request('injuryFile', {...item, deleted: true}).then((response: any) => {
					this.props.actions.deleteItem('injuryFiles', item.id);
				});
			} else {
				this.setState((prevState) => ({
					unsavedItems: prevState.unsavedItems.filter(item => item.id != item.id)
				}));
				this.props.onUnsavedItemDeleted(item);
			}
			request('uploadedFile', {...item.file, deleted: true}).then((result) => {
				let newFilesList = this.props.item.files.list.slice();
				let fileIndex = newFilesList.indexOf(item);
				newFilesList.splice(fileIndex, 1);
			})
		}, 'File will be deleted. Are you Sure?');
	}

	handleAddFileDialogOkClick(newFile) {
		
		if (this.props.item.id > 0) {
			request('injuryFile', {
				...newFile,
				objectId: this.props.item.id
			}).then((response) => {
				this.addFile(response as InjuryFile);

				this.setState({
					addFileDialogIsVisible: false
				});
			});
		} else {
			this.addFile(newFile);

			this.setState({
				addFileDialogIsVisible: false
			});
		}
	}
	
	render() {
		const items = this.props.items ? this.props.items.items : this.state.unsavedItems;
		const isEditable = this.props.isEditable;
		
		return <React.Fragment>
			{isEditable &&
			<div className="form-group">
				<button type="button" className="button-component btn btn-primary" onClick={this.showAddFileDialog}>Upload Document </button>
				<InjuryFilePopup visible={this.state.addFileDialogIsVisible} onCancel={this.hideAddFileDialog} onOk={this.handleAddFileDialogOkClick}/>
			</div>}
			{items && items.length
				? <div className="tbl-document"><Table
					columns={[{
						title: 'File Name',
						key: 'fileName',
						render: (text, record: any) => <React.Fragment>
							<div className="table-mobile__caption">File Name</div>
							<div className="table-mobile__content">{record.file.name}</div>
						</React.Fragment>
					}, {
						title: 'Description',
						key: 'description',
						render: (text, record: any) => renderMobileCell('Description', record.description)
					},{
						title: 'Uploaded Date',
						key: 'uploadedDate',
						render: (text, record: any) => renderMobileCell('Uploaded Date', dateFormat(record.file.time))
					}, {
						title: '',
						key: 'actions',
						render: (text, record: any) => (
							<div className="table-actions">
								<a className="btn btn-sm btn-default" title="Download" href={record.file.src} target="_blank" download><i className="fa fa-download"></i></a>
								{ isEditable && this.props.userRole !== UserRole.External &&
									<button className="btn btn-sm btn-danger" title="Delete" onClick={e => this.deleteFile(e, record)}><i className="fa fa-trash"></i></button>
								}
							</div>
						)
					}]}
					dataSource={items}
					rowKey="id"/></div>
				: "No files uploaded"}
			</React.Fragment>
	}
}

export default connect<ReduxProps, any, OwnProps>(
	(state: ApplicationState, ownProps?: OwnProps) => {
		return {
			items: state.injuryFiles[ownProps!.item!.id]
		}
	},
	(dispatch, ownProps?: OwnProps) => {
		if (!ownProps || ownProps.item.id <= 0) { return {}; }

		return {
			actions: bindActionCreators(getSelectedInjuryFilesActionCreators(ownProps.item.id), dispatch)
		}
	}
)(InjuryFiles);